import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import About from "./components/About";
import Header from "./components/Header";
import SignIn from "./components/Auth/SignIn";
import SignUp from "./components/Auth/SignUp";
import ForgotPassword from "./components/Auth/ForgotPwd";
import ResetPassword from "./components/Auth/ResetPassword";
import VerifyEmail from "./components/Auth/VerifyEmail";
import Profile from "./components/Auth/Profile";
import ActionHandler from "./components/Auth/ActionHandler";
import ProtectedRoute from "./components/ProtectedRoute";
import EmailVerifiedRoute from "./components/EmailVerifiedRoute";
import NotFound from "./components/NotFound";

const App = () => {
  return (
    <Router>
      {/* 导航 */}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<About />} />
        <Route path="/faq" element={<About />} />
        <Route path="/contact" element={<About />} />
        <Route path="/privacy_policy" element={<About />} />
        <Route path="/auth" element={<ActionHandler />} />
        <Route path="/auth/login" element={<SignIn />} />
        <Route path="/auth/signup" element={<SignUp />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />
        <Route
          path="/auth/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/auth/verify-email"
          element={
            <EmailVerifiedRoute>
              <VerifyEmail />
            </EmailVerifiedRoute>
          }
        />
        <Route
          path="/auth/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
